import { ApplicationConfig } from '@angular/core'
import { provideEffects } from '@ngrx/effects'
import { provideState } from '@ngrx/store'

import * as competitorsEffects from './store/competitors.effects'
import * as fromCompetitors from './store/competitors.reducer'

export const competitorsConfig: ApplicationConfig['providers'] = [
  provideState(fromCompetitors.featureKey, fromCompetitors.reducer),
  provideEffects(competitorsEffects),
]

export const competitorsI18nConfig: ApplicationConfig['providers'] = []
