import { createAction } from '@ngrx/store'

import { JsonResponse } from '~core/models'
import { createHTTPActions } from '~store/utils.actions'

import {
  Competitor,
  CompetitorHashtagsOverview,
  CompetitorInsights,
  CompetitorInsightsRequest,
  CompetitorPayload,
  CompetitorTopContent,
  FbInsightsRequest,
  FbPageSearchItem,
  IgAccountSearchItem,
  IgInsightsRequest,
  PublicFacebookPageInsights,
  PublicInstagramAccountInsights,
} from '../models'

export const [loadCompetitors, loadCompetitorsSuccess, loadCompetitorsError] = createHTTPActions<string, Competitor[]>(
  '[Competitors] Load Competitors',
)

export const [loadCompetitorInsights, loadCompetitorInsightsSuccess, loadCompetitorInsightsError] = createHTTPActions<
  CompetitorInsightsRequest,
  CompetitorInsights
>('[Competitors] Load Competitor Insights')

export const [loadIgAccountInsights, loadIgAccountInsightsSuccess, loadIgAccountInsightsError] = createHTTPActions<
  IgInsightsRequest,
  PublicInstagramAccountInsights
>('[Competitors] Load Instagram Account Insights')

export const [loadFbPageInsights, loadFbPageInsightsSuccess, loadFbPageInsightsError] = createHTTPActions<
  FbInsightsRequest,
  PublicFacebookPageInsights
>('[Competitors] Load Facebook Page Insights')

export const [loadIgAccountTopContent, loadIgAccountTopContentSuccess, loadIgAccountTopContentError] =
  createHTTPActions<CompetitorInsightsRequest, CompetitorTopContent>('[Competitors] Load Instagram Account Top Content')

export const [loadFbPageTopContent, loadFbPageTopContentSuccess, loadFbPageTopContentError] = createHTTPActions<
  CompetitorInsightsRequest,
  CompetitorTopContent
>('[Competitors] Load Facebook Page Top Content')

export const [loadIgAccountHashtagsOverview, loadIgAccountHashtagsOverviewSuccess, loadIgAccountHashtagsOverviewError] =
  createHTTPActions<CompetitorInsightsRequest, CompetitorHashtagsOverview>(
    '[Competitors] Load Instagram Account Hashtags Overview',
  )

export const [loadFbPageHashtagsOverview, loadFbPageHashtagsOverviewSuccess, loadFbPageHashtagsOverviewError] =
  createHTTPActions<CompetitorInsightsRequest, CompetitorHashtagsOverview>(
    '[Competitors] Load Facebook Page Hashtags Overview',
  )

export const [searchFbPages, searchFbPagesSuccess, searchFbPagesError] = createHTTPActions<string, FbPageSearchItem[]>(
  '[Competitors] Search Facebook Pages',
)

export const [searchIgAccounts, searchIgAccountsSuccess, searchIgAccountsError] = createHTTPActions<
  string,
  IgAccountSearchItem[]
>('[Competitors] Search Instagram Accounts')

// Mutations
export const [createCompetitor, createCompetitorSuccess, createCompetitorError] = createHTTPActions<
  CompetitorPayload,
  Competitor
>('[Competitors] Create Competitor')

export const [patchCompetitor, patchCompetitorSuccess, patchCompetitorError] = createHTTPActions<
  Partial<CompetitorPayload>,
  Competitor
>('[Competitors] Update Competitor')

export const [deleteCompetitor, deleteCompetitorSuccess, deleteCompetitorError] = createHTTPActions<string, Competitor>(
  '[Competitors] Delete Competitor',
)

export const resetLastMutatedCompetitor = createAction('[Competitors] Reset Added/Updated Competitor')

export const resetState = createAction('[Competitors] Reset State')
