import { HttpErrorResponse } from '@angular/common/http'
import { inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, exhaustMap, map } from 'rxjs/operators'

import { CompetitorsApiService } from '../services/competitors.api.service'
import * as CompetitorActions from './competitors.actions'

export const loadCompetitors = createEffect(
  (actions$ = inject(Actions), competitorsApiService = inject(CompetitorsApiService)) => {
    return actions$.pipe(
      ofType(CompetitorActions.loadCompetitors),
      exhaustMap(({ payload: projectId }) => {
        return competitorsApiService.getAll(projectId).pipe(
          map((competitors) => CompetitorActions.loadCompetitorsSuccess(competitors)),
          catchError((err: HttpErrorResponse) => of(CompetitorActions.loadCompetitorsError(err))),
        )
      }),
    )
  },
  { functional: true },
)

export const loadCompetitorInsights = createEffect(
  (actions$ = inject(Actions), competitorsApiService = inject(CompetitorsApiService)) => {
    return actions$.pipe(
      ofType(CompetitorActions.loadCompetitorInsights),
      exhaustMap(({ payload }) => {
        return competitorsApiService.getInsights(payload).pipe(
          map((insights) => CompetitorActions.loadCompetitorInsightsSuccess(insights)),
          catchError((err: HttpErrorResponse) => of(CompetitorActions.loadCompetitorInsightsError(err))),
        )
      }),
    )
  },
  { functional: true },
)

export const loadIgAccountInsights = createEffect(
  (actions$ = inject(Actions), competitorsApiService = inject(CompetitorsApiService)) => {
    return actions$.pipe(
      ofType(CompetitorActions.loadIgAccountInsights),
      exhaustMap(({ payload }) => {
        return competitorsApiService.getIgAccountInsights(payload).pipe(
          map((insights) => CompetitorActions.loadIgAccountInsightsSuccess(insights)),
          catchError((err: HttpErrorResponse) => of(CompetitorActions.loadIgAccountInsightsError(err))),
        )
      }),
    )
  },
  { functional: true },
)

export const loadFbPageInsights = createEffect(
  (actions$ = inject(Actions), competitorsApiService = inject(CompetitorsApiService)) => {
    return actions$.pipe(
      ofType(CompetitorActions.loadFbPageInsights),
      exhaustMap(({ payload }) => {
        return competitorsApiService.getFbPageInsights(payload).pipe(
          map((insights) => CompetitorActions.loadFbPageInsightsSuccess(insights)),
          catchError((err: HttpErrorResponse) => of(CompetitorActions.loadFbPageInsightsError(err))),
        )
      }),
    )
  },
  { functional: true },
)

export const loadIgAccountTopContent = createEffect(
  (actions$ = inject(Actions), competitorsApiService = inject(CompetitorsApiService)) => {
    return actions$.pipe(
      ofType(CompetitorActions.loadIgAccountTopContent),
      exhaustMap(({ payload }) => {
        return competitorsApiService.getIgAccountTopContent(payload).pipe(
          map((content) => CompetitorActions.loadIgAccountTopContentSuccess(content)),
          catchError((err: HttpErrorResponse) => of(CompetitorActions.loadIgAccountTopContentError(err))),
        )
      }),
    )
  },
  { functional: true },
)

export const loadFbPageTopContent = createEffect(
  (actions$ = inject(Actions), competitorsApiService = inject(CompetitorsApiService)) => {
    return actions$.pipe(
      ofType(CompetitorActions.loadFbPageTopContent),
      exhaustMap(({ payload }) => {
        return competitorsApiService.getFbPageTopContent(payload).pipe(
          map((content) => CompetitorActions.loadFbPageTopContentSuccess(content)),
          catchError((err: HttpErrorResponse) => of(CompetitorActions.loadFbPageTopContentError(err))),
        )
      }),
    )
  },
  { functional: true },
)

export const loadIgAccountHashtagsOverview = createEffect(
  (actions$ = inject(Actions), competitorsApiService = inject(CompetitorsApiService)) => {
    return actions$.pipe(
      ofType(CompetitorActions.loadIgAccountHashtagsOverview),
      exhaustMap(({ payload }) => {
        return competitorsApiService.getIgAccountHashtagsOverview(payload).pipe(
          map((content) => CompetitorActions.loadIgAccountHashtagsOverviewSuccess(content)),
          catchError((err: HttpErrorResponse) => of(CompetitorActions.loadIgAccountHashtagsOverviewError(err))),
        )
      }),
    )
  },
  { functional: true },
)

export const loadFbPageHashtagsOverview = createEffect(
  (actions$ = inject(Actions), competitorsApiService = inject(CompetitorsApiService)) => {
    return actions$.pipe(
      ofType(CompetitorActions.loadFbPageHashtagsOverview),
      exhaustMap(({ payload }) => {
        return competitorsApiService.getFbPageHashtagsOverview(payload).pipe(
          map((content) => CompetitorActions.loadFbPageHashtagsOverviewSuccess(content)),
          catchError((err: HttpErrorResponse) => of(CompetitorActions.loadFbPageHashtagsOverviewError(err))),
        )
      }),
    )
  },
  { functional: true },
)

export const searchFbPages = createEffect(
  (actions$ = inject(Actions), competitorsApiService = inject(CompetitorsApiService)) => {
    return actions$.pipe(
      ofType(CompetitorActions.searchFbPages),
      exhaustMap(({ payload }) => {
        return competitorsApiService.searchFbPages(payload).pipe(
          map((content) => CompetitorActions.searchFbPagesSuccess(content)),
          catchError((err: HttpErrorResponse) => of(CompetitorActions.searchFbPagesError(err))),
        )
      }),
    )
  },
  { functional: true },
)

export const searchIgAccounts = createEffect(
  (actions$ = inject(Actions), competitorsApiService = inject(CompetitorsApiService)) => {
    return actions$.pipe(
      ofType(CompetitorActions.searchIgAccounts),
      exhaustMap(({ payload }) => {
        return competitorsApiService.searchIgAccounts(payload).pipe(
          map((content) => CompetitorActions.searchIgAccountsSuccess(content)),
          catchError((err: HttpErrorResponse) => of(CompetitorActions.searchIgAccountsError(err))),
        )
      }),
    )
  },
  { functional: true },
)

// Mutations
export const createCompetitor = createEffect(
  (actions$ = inject(Actions), competitorsApiService = inject(CompetitorsApiService)) => {
    return actions$.pipe(
      ofType(CompetitorActions.createCompetitor),
      exhaustMap(({ payload }) => {
        return competitorsApiService.create(payload).pipe(
          map((competitor) => CompetitorActions.createCompetitorSuccess(competitor)),
          catchError((err: HttpErrorResponse) => of(CompetitorActions.createCompetitorError(err))),
        )
      }),
    )
  },
  { functional: true },
)

export const patchCompetitor = createEffect(
  (actions$ = inject(Actions), competitorsApiService = inject(CompetitorsApiService)) => {
    return actions$.pipe(
      ofType(CompetitorActions.patchCompetitor),
      exhaustMap(({ payload }) => {
        return competitorsApiService.update(payload).pipe(
          map((competitor) => CompetitorActions.patchCompetitorSuccess(competitor)),
          catchError((err: HttpErrorResponse) => of(CompetitorActions.patchCompetitorError(err))),
        )
      }),
    )
  },
  { functional: true },
)

export const deleteCompetitor = createEffect(
  (actions$ = inject(Actions), competitorsApiService = inject(CompetitorsApiService)) => {
    return actions$.pipe(
      ofType(CompetitorActions.deleteCompetitor),
      exhaustMap(({ payload }) => {
        return competitorsApiService.delete(payload).pipe(
          map((competitor) => CompetitorActions.deleteCompetitorSuccess(competitor)),
          catchError((err: HttpErrorResponse) => of(CompetitorActions.deleteCompetitorError(err))),
        )
      }),
    )
  },
  { functional: true },
)
