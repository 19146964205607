import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { ApiService } from '~core/services'

import {
  Competitor,
  CompetitorHashtagsOverview,
  CompetitorInsights,
  CompetitorInsightsRequest,
  CompetitorPayload,
  CompetitorTopContent,
  FbInsightsRequest,
  FbPageSearchItem,
  IgAccountSearchItem,
  IgInsightsRequest,
  PublicFacebookPageInsights,
  PublicInstagramAccountInsights,
} from '../models'

@Injectable({
  providedIn: 'root',
})
export class CompetitorsApiService {
  prefix = '/v1.1/competitors'
  prefixV2 = '/competitors'
  headersV2 = { 'Sbam-Api-Version': '2.0' }

  constructor(private apiService: ApiService) {}

  // Queries
  getAll = (project: string): Observable<Competitor[]> => {
    const params = new HttpParams({ fromObject: { project } })
    return this.apiService.get(`${this.prefixV2}`, params, { headers: this.headersV2 })
  }

  getInsights = (request: CompetitorInsightsRequest): Observable<CompetitorInsights> => {
    const params = new HttpParams({ fromObject: { ...request } })
    return this.apiService.get(`${this.prefixV2}/insights`, params, { headers: this.headersV2 })
  }

  getIgAccountInsights = (request: IgInsightsRequest): Observable<PublicInstagramAccountInsights> => {
    const params = new HttpParams({ fromObject: { ...request } })
    return this.apiService.get(`${this.prefixV2}/instagram-accounts/insights`, params, { headers: this.headersV2 })
  }

  getFbPageInsights = (request: FbInsightsRequest): Observable<PublicFacebookPageInsights> => {
    const params = new HttpParams({ fromObject: { ...request } })
    return this.apiService.get(`${this.prefixV2}/facebook-pages/insights`, params, { headers: this.headersV2 })
  }

  getIgAccountTopContent = (request: CompetitorInsightsRequest): Observable<CompetitorTopContent> => {
    const params = new HttpParams({ fromObject: { ...request } })
    return this.apiService.get(`${this.prefixV2}/instagram-accounts/content`, params, { headers: this.headersV2 })
  }

  getFbPageTopContent = (request: CompetitorInsightsRequest): Observable<CompetitorTopContent> => {
    const params = new HttpParams({ fromObject: { ...request } })
    return this.apiService.get(`${this.prefixV2}/facebook-pages/content`, params, { headers: this.headersV2 })
  }

  getIgAccountHashtagsOverview = (request: CompetitorInsightsRequest): Observable<CompetitorHashtagsOverview> => {
    const params = new HttpParams({ fromObject: { ...request } })
    return this.apiService.get(`${this.prefixV2}/instagram-accounts/hashtags`, params, { headers: this.headersV2 })
  }

  getFbPageHashtagsOverview = (request: CompetitorInsightsRequest): Observable<CompetitorHashtagsOverview> => {
    const params = new HttpParams({ fromObject: { ...request } })
    return this.apiService.get(`${this.prefixV2}/facebook-pages/hashtags`, params, { headers: this.headersV2 })
  }

  searchFbPages = (searchString: string): Observable<FbPageSearchItem[]> =>
    this.apiService.get(`${this.prefix}/facebook-pages?q=${searchString}`)

  searchIgAccounts = (searchString: string): Observable<IgAccountSearchItem[]> =>
    this.apiService.get(`${this.prefix}/instagram-accounts?q=${searchString}`)

  // Mutations
  create = (competitor: CompetitorPayload): Observable<Competitor> => this.apiService.post(this.prefix, competitor)

  update = (competitor: CompetitorPayload): Observable<Competitor> =>
    this.apiService.patch(`${this.prefix}/${competitor.id}`, competitor)

  delete = (id: string): Observable<Competitor> => this.apiService.delete(`${this.prefix}/${id}`)
}
