import { createReducer, on } from '@ngrx/store'

import {
  Competitor,
  CompetitorHashtagsOverview,
  CompetitorInsights,
  CompetitorTopContent,
  FbPageSearchItem,
  IgAccountSearchItem,
  PublicFacebookPageInsights,
  PublicInstagramAccountInsights,
} from '../models'
import * as CompetitorsActions from './competitors.actions'

export const featureKey = 'competitors'

export type State = {
  competitors: Competitor[]
  lastMutatedCompetitor: Competitor
  isLoading: boolean
  currentRequests: number
  insights: CompetitorInsights
  instagramAccountInsights: PublicInstagramAccountInsights
  facebookPageInsights: PublicFacebookPageInsights
  instagramAccountTopContent: CompetitorTopContent
  facebookPageTopContent: CompetitorTopContent
  instagramAccountHashtags: CompetitorHashtagsOverview
  facebookPageHashtags: CompetitorHashtagsOverview
  searchResults: {
    facebookPages: { data: FbPageSearchItem[]; isLoading: boolean }
    instagramAccounts: { data: IgAccountSearchItem[]; isLoading: boolean }
  }
}

export const initialState: State = {
  competitors: [],
  lastMutatedCompetitor: null,
  isLoading: false,
  currentRequests: 0,
  insights: null,
  instagramAccountInsights: null,
  facebookPageInsights: null,
  instagramAccountTopContent: null,
  facebookPageTopContent: {
    posts: null,
  },
  instagramAccountHashtags: null,
  facebookPageHashtags: null,
  searchResults: {
    facebookPages: { data: [], isLoading: false },
    instagramAccounts: { data: [], isLoading: false },
  },
}

const setCompetitors = (state: State, action): State => {
  const st = decrementRequestCount(state)
  return {
    ...st,
    competitors: action.payload,
  }
}

const setCompetitorInsights = (state: State, action): State => {
  const st = decrementRequestCount(state)
  return {
    ...st,
    insights: action.payload,
  }
}

const setInstagramAccountInsights = (state: State, action): State => {
  const st = decrementRequestCount(state)
  return {
    ...st,
    instagramAccountInsights: action.payload,
  }
}

const setFacebookPageInsights = (state: State, action): State => {
  const st = decrementRequestCount(state)
  return {
    ...st,
    facebookPageInsights: action.payload,
  }
}

const setInstagramAccountTopContent = (state: State, action): State => {
  const st = decrementRequestCount(state)
  return {
    ...st,
    instagramAccountTopContent: action.payload,
  }
}

const setFacebookPageTopContent = (state: State, action): State => {
  const st = decrementRequestCount(state)
  return {
    ...st,
    facebookPageTopContent: action.payload,
  }
}

const setInstagramAccountHashtags = (state: State, action): State => {
  const st = decrementRequestCount(state)
  return {
    ...st,
    instagramAccountHashtags: action.payload,
  }
}

const setFacebookPageHashtags = (state: State, action): State => {
  const st = decrementRequestCount(state)
  return {
    ...st,
    facebookPageHashtags: action.payload,
  }
}

const initFbPagesSearch = (state: State, action): State => {
  return {
    ...state,
    searchResults: {
      ...state.searchResults,
      facebookPages: { ...state.searchResults.facebookPages, isLoading: true },
    },
  }
}

const setFbPagesSearchResults = (state: State, action): State => {
  return {
    ...state,
    searchResults: {
      ...state.searchResults,
      facebookPages: { data: action.payload, isLoading: false },
    },
  }
}

const handleFbPagesSearchError = (state: State, action): State => {
  return {
    ...state,
    searchResults: {
      ...state.searchResults,
      facebookPages: { data: [], isLoading: false },
    },
  }
}

const initIgAccountsSearch = (state: State, action): State => {
  return {
    ...state,
    searchResults: {
      ...state.searchResults,
      instagramAccounts: { ...state.searchResults.instagramAccounts, isLoading: true },
    },
  }
}

const setIgAccountsSearchResults = (state: State, action): State => {
  return {
    ...state,
    searchResults: {
      ...state.searchResults,
      instagramAccounts: { data: action.payload, isLoading: false },
    },
  }
}

const handleIgAccountsSearchError = (state: State, action): State => {
  return {
    ...state,
    searchResults: {
      ...state.searchResults,
      instagramAccounts: { data: [], isLoading: false },
    },
  }
}

const createCompetitor = (state: State, action): State => {
  return {
    ...state,
    competitors: [...state.competitors, action.payload],
    lastMutatedCompetitor: action.payload,
  }
}

const resetLastMutatedCompetitor = (state: State): State => {
  return {
    ...state,
    lastMutatedCompetitor: null,
  }
}

const updateCompetitor = (state: State, action): State => {
  return {
    ...state,
    competitors: state.competitors.map((c) => (c.id === action.payload.id ? action.payload : c)),
    lastMutatedCompetitor: action.payload,
  }
}

const setIsLoading = (state: State, action): State => ({
  ...state,
  isLoading: true,
})

const incrementRequestCount = (state: State): State => ({
  ...state,
  currentRequests: state.currentRequests + 1,
})

const decrementRequestCount = (state: State): State => ({
  ...state,
  currentRequests: state.currentRequests - 1,
})

const resetState = (): State => initialState

export const reducer = createReducer(
  initialState,

  // RequestCount
  on(
    CompetitorsActions.loadCompetitors,
    CompetitorsActions.loadCompetitorInsights,
    CompetitorsActions.loadFbPageInsights,
    CompetitorsActions.loadIgAccountInsights,
    CompetitorsActions.loadIgAccountTopContent,
    CompetitorsActions.loadFbPageTopContent,
    CompetitorsActions.loadIgAccountHashtagsOverview,
    CompetitorsActions.loadFbPageHashtagsOverview,
    incrementRequestCount,
  ),

  // Channels search
  on(CompetitorsActions.searchFbPages, initFbPagesSearch),
  on(CompetitorsActions.searchFbPagesSuccess, setFbPagesSearchResults),
  on(CompetitorsActions.searchFbPagesError, handleFbPagesSearchError),
  on(CompetitorsActions.searchIgAccounts, initIgAccountsSearch),
  on(CompetitorsActions.searchIgAccountsSuccess, setIgAccountsSearchResults),
  on(CompetitorsActions.searchIgAccountsError, handleIgAccountsSearchError),

  // Completed api call
  on(CompetitorsActions.loadCompetitorsSuccess, setCompetitors),
  on(CompetitorsActions.loadCompetitorInsightsSuccess, setCompetitorInsights),
  on(CompetitorsActions.loadFbPageInsightsSuccess, setFacebookPageInsights),
  on(CompetitorsActions.loadIgAccountInsightsSuccess, setInstagramAccountInsights),
  on(CompetitorsActions.loadIgAccountTopContentSuccess, setInstagramAccountTopContent),
  on(CompetitorsActions.loadFbPageTopContentSuccess, setFacebookPageTopContent),
  on(CompetitorsActions.loadIgAccountHashtagsOverviewSuccess, setInstagramAccountHashtags),
  on(CompetitorsActions.loadFbPageHashtagsOverviewSuccess, setFacebookPageHashtags),
  on(CompetitorsActions.createCompetitorSuccess, createCompetitor),
  on(CompetitorsActions.patchCompetitorSuccess, updateCompetitor),

  // Errors
  // on(
  //   CompetitorsActions.loadInsightsError,
  //   CompetitorsActions.loadInsightsOverviewError,
  //   CompetitorsActions.loadStoriesActivityError,
  //   CompetitorsActions.loadAudienceChangeError,
  //   CompetitorsActions.loadOnlineFollowersPerHourError,
  //   CompetitorsActions.loadPostsError,
  //   CompetitorsActions.loadPostError,
  //   CompetitorsActions.loadStoriesError,
  //   CompetitorsActions.loadReelsError,
  //   CompetitorsActions.loadReelError,
  //   setError,
  // ),

  // Reset state
  on(CompetitorsActions.resetLastMutatedCompetitor, resetLastMutatedCompetitor),
  on(CompetitorsActions.resetState, resetState),
)
